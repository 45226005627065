import { TeaserItem } from 'designsystem/components/3-organisms/TeaserModule';
import { LinkFeature } from '../Link';
import { VideoFeature } from '../Video';

export const TeaserItemFeature: React.FC<Content.TeaserItem> = ({ content, settings }) => {
	const { heading, tempImage, picture, entryId, videoUrl, text, link, urlWrapperLink } = content?.properties ?? {};
	const { style, imageDisplayStyle } = settings?.properties ?? {};

	const imageObj = picture?.length > 0 ? picture[0]?.content?.properties.image : tempImage;
	const { url, focalPoint } = imageObj ?? {};
	const altText = picture?.length > 0 ? picture[0]?.content?.properties.altText : imageObj?.properties?.altText;
	const altTextChecked = altText == '""' ? '' : altText;

	return (
		<TeaserItem
			heading={heading}
			picture={
				url && {
					altText: altTextChecked,
					url,
					focalPoint,
				}
			}
			video={
				(videoUrl || entryId) && (
					<VideoFeature
						content={{ properties: { url: videoUrl, entryId: entryId, title: heading } }}
						settings={{ properties: { aspectRatio: '16:9' } }}
						noBackground={true}
					/>
				)
			}
			imageDisplayStyle={imageDisplayStyle}
			imagePosition="Top"
			text={text}
			link={link && <LinkFeature style="arrow" {...link} />}
			urlWrapperLink={urlWrapperLink}
			backgroundStyle={style}
			style="contained"
		/>
	);
};
